import React from "react";
import { motion } from "framer-motion";

const NotFoundPage = () => {
  return (
    <>
      <div className="grid place-items-center md:min-h-screen">
        <div>
          <motion.h1
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              ease: [0.6, 0.05, -0.01, 0.9],
              mass: 10,
              damping: 500,
            }}
            className="text-5xl font-body px-5 sm:px-32 md:px-52 leading-loose mb-2 text-center"
          >
            🤷‍♂️ oof.
          </motion.h1>
          <h2 className="text-center font-body text-lg">
            I'm not sure what you're looking for, but you won't find it here.
          </h2>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
